<template>
	<div class="submenu-container">
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<div class="submenu" :class="{ active: hideAll ? resetState() : active }">
			<ul>
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMobileNav">
						{{ item.header }}
					</nuxt-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-mobile-nav']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMobileNav = () => {
	emit('hide-mobile-nav');
};
</script>

<style lang="scss" scoped>
.submenu-container {
	position: relative;
}

a {
	font-weight: 400;
	color: #fff;

	&.active,
	&:hover {
		color: var(--cta-color-hover);
	}
}

.mobile-dropdown {
	display: none;
	width: 18px;
	height: 20px;
	background: url('~/assets/images/icon-dropdown-white.png') center center no-repeat;
	margin-bottom: -3px;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	ul {
		display: block;
		background: #c1a99d;
		position: absolute;
		overflow: hidden;
		top: 5px;
		left: 10px;
		text-align: left;
		padding: 9px 7px;
		min-width: 150px;
		margin-top: 10px;
		opacity: 0;
		max-height: 0;
		z-index: -1;
		transition:
			margin 0.3s ease-in-out,
			opacity 0.3s ease-in-out,
			max-height 0.3s ease-in-out;

		&.active {
			display: block;
			margin-top: 0;
			z-index: 1;
			max-height: 500px;
		}

		li {
			padding: 5px 0;
			list-style: none;
			display: inline-block;
			text-wrap: nowrap;
			width: 100%;

			a {
				margin: 0 10px;
				text-decoration: none;
				font-size: 13px;
				font-weight: 400;
				letter-spacing: 1px;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	&.active ul {
		display: block;
		max-height: 500px;
	}
}

@media (max-width: 1200px) {
	.mobile-dropdown {
		display: inline-block;
	}

	.submenu-container {
		display: inline;
	}

	.submenu ul {
		position: static;
		background: transparent;
		text-align: center;
		text-transform: uppercase;
		padding: 0 0 5px;
		display: none;
		opacity: 1;

		&.active {
			display: block;
		}
	}
}
</style>
